@import "node_modules/angular-notifier/styles";
$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";
@import "node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
@import "node_modules/@fortawesome/fontawesome-free/scss/solid";
@import "node_modules/@fortawesome/fontawesome-free/scss/regular";
@font-face {
    font-family: "Gotham-Book-Regular";
    src: url(./assets/fonts/Gotham-Book-Regular.otf) format("opentype");
}

@font-face {
    font-family: "Gotham-Bold";
    src: url(./assets/fonts/Gotham-Bold.otf) format("opentype");
}

@font-face {
    font-family: "Gotham-Book-Italic";
    src: url(./assets/fonts/Gotham-BookIta.otf) format("opentype");
}

@font-face {
    font-family: "Gotham-Book";
    src: url(./assets/fonts/Gotham-Book.otf) format("opentype");
}

@font-face {
    font-family: "Gotham-Light";
    src: url(./assets/fonts/Gotham-Light.otf) format("opentype");
}

@font-face {
    font-family: "Gotham-Medium";
    src: url(./assets/fonts/Gotham-Medium.otf) format("opentype");
}

@font-face {
    font-family: "Gotham-Medium-Italic";
    src: url(./assets/fonts/Gotham-MediumIta.otf) format("opentype");
}

@font-face {
    font-family: "Gotham-Medium-Italic";
    src: url(./assets/fonts/Gotham-MediumIta.otf) format("opentype");
}

@font-face {
    font-family: "Caveat-Bold";
    src: url(./assets/fonts/Caveat/Caveat-Bold.ttf) format("truetype");
}

@font-face {
    font-family: "Caveat-Regular";
    src: url(./assets/fonts/Caveat/Caveat-Regular.ttf) format("truetype");
}

* {
    margin: 0;
    padding: 0;
    letter-spacing: 0.8px;
    outline: none !important;
}

body {
    font-family: "Gotham-Book";
    font-size: 12px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Gotham-Bold";
    color: #42643c;
}

body::-webkit-scrollbar,
div::-webkit-scrollbar {
    width: 5px;
}

body::-webkit-scrollbar-track,
div::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px #004C4C;
}

body::-webkit-scrollbar-thumb,
div::-webkit-scrollbar-thumb {
    background-color: #004C4C;
    outline: 1px solid #004C4C;
}

.slim-loading-bar-progress {
    height: 6px !important;
    background-color: #004C4C !important;
    color: #004C4C !important;
}


/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


/* Firefox */

input[type="number"] {
    -moz-appearance: textfield;
}

.form-control,
.form-control:focus {
    box-shadow: none;
    border-radius: 5px;
    height: 35px;
    border: 1px solid #42643c;
    font-size: 12px;
}

textarea,
textarea:focus {
    box-shadow: none !important;
    border-radius: 5px;
    border: 1px solid #42643c !important;
    font-size: 12px;
    padding: 6px 12px;
}

label {
    font-family: "Gotham-Medium";
    font-size: 14px;
    color: #42643c;
}

.btn {
    font-family: "Gotham-Medium";
    border: none;
    cursor: pointer;
    padding-top: 8px;
    padding-bottom: 8px;
    display: inline-block;
    text-transform: uppercase;
    outline: none;
    position: relative;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    box-shadow: none;
}

.btn:after {
    content: "";
    position: absolute;
    z-index: -1;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

.btn-2 {
    background: #42643c;
    color: #fff;
    box-shadow: 0 6px #42643c;
    -webkit-transition: none;
    -moz-transition: none;
    transition: none;
}

.btn-2h {
    border-radius: 10px;
}

.btn-2h:hover {
    box-shadow: 0 4px #004C4C;
    top: 2px;
    color: #ffffff;
}

.btn-2h:active {
    box-shadow: 0 0 #004C4C;
    top: 6px;
}

.btn:focus {
    box-shadow: none;
}

.style-b {
    font-family: "Gotham-Bold";
}

.style-i {
    font-family: "Gotham-Book-Italic";
}

.style-m {
    font-family: "Gotham-Medium";
}

.page-wrapper.toggled .sidebar {
    left: -300px;
}

// angular perfect scrollbar style
.sidebar .ps.ps--scrolling-y>.ps__rail-y,
.sidebar>.ps.ps--scrolling-x>.ps__rail-x,
.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
    background-color: transparent !important;
}

.sidebar .ps.ps--active-y>.ps__rail-y:hover,
.sidebar>.ps.ps--active-y>.ps__rail-y.ps--clicking {
    width: 10px !important;
}

.f-16 {
    font-size: 16px !important;
}

.f-14 {
    font-size: 14px !important;
}

.ps__thumb-y {
    background-color: #fff !important;
    width: 6px !important;
    border-radius: 0px !important;
    opacity: 0.5;
}

.ecom-table .table-header {
    font-family: "Gotham-Bold";
    background-color: #42643c;
    color: #ffffff;
    font-size: 13px;
    letter-spacing: 0.8px;
    font-weight: normal !important;
}

.table-header .ecom-table tr,
.dsa-table td {
    font-family: "Gotham-Book";
    border-top: none;
    vertical-align: middle;
    font-size: 12px;
}

.ecom-table tr td,
.ecom-table th,
.ecom-table thead {
    border: 1px solid #DEE2E6 !important;
}

.ecom-table tr:nth-child(even) {
    background-color: #f7f7f7;
}

.ecom-table span {
    cursor: pointer;
}

.container-buffer {
    padding-top: 75px;
}

.header-buffer {
    padding-top: 50px;
}

.sub-header-buffer {
    padding-top: 25px;
}

i {
    cursor: pointer;
    font-size: 15px;
    font-family: "Gotham-Bold";
}

.green {
    color: #00b894;
}

.red {
    color: #d63031;
}

.black {
    color: #000000;
}

.btn-ecom {
    background-color: #42643c;
    color: #ffffff;
    font-size: 12px;
}

.btn-ecom:hover {
    color: #ffffff;
}

.c-btn {
    min-height: 30px !important;
    height: 33px !important;
    border: 1px solid #42643c;
    border-radius: 5px !important;
}

.clear-all {
    display: none !important;
}

.selected-list .c-angle-down svg,
.selected-list .c-angle-up svg {
    fill: #42643c !important;
    opacity: 1 !important;
    top: 42% !important;
}

.dropdown-list label {
    font-size: 12px !important;
    font-weight: normal !important;
    font-family: "Gotham-Book" !important;
}

.selected-list .c-angle-down,
.selected-list .c-angle-up {
    top: 42% !important;
}

.row-reverse {
    flex-direction: row-reverse;
}

.modal {
    margin-top: 3%;
}

.modal-content {
    border-radius: 20px;
    background-color: #ffffff;
}

.modal-dialog {
    width: 450px;
}


/* The container */

.ecom-cb {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


/* Hide the browser's default checkbox */

.ecom-cb input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}


/* Create a custom checkbox */

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #ffffff;
    border: 1px solid #dcf6ff;
}


/* On mouse-over, add a grey background color */

.ecom-cb:hover input~.checkmark {
    background-color: #ffffff;
}


/* When the checkbox is checked, add a blue background */

.ecom-cb input:checked~.checkmark {
    background-color: #42643c;
}


/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}


/* Show the checkmark when checked */

.ecom-cb input:checked~.checkmark:after {
    display: block;
}

.ecom-cb .checkmark:after {
    left: 7px;
    top: 2px;
    width: 6px;
    height: 13px;
    border: solid #ffffff;
    border-width: 0px 2px 2px 0;
    transform: rotate(45deg);
}

/* barcode */

// ngx-barcode6 svg{
//     margin-top: -8px;
// }